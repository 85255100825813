import React from "react"
import { Link } from "gatsby"
import {
  FeatureDescriptionWrapper,
  FeatureItem,
  FeatureItemDescription,
  FeatureItemIcon,
  FeatureItemTitle,
  FeatureItemWrapper,
} from "./styles/FeatureSection.styled"
import OptimizedImage from "../../utils/optimizedImage"
import { LottieWrapper } from "../Lottie/LottieWrapper"

export const IconSection = ({ icon, unrestrictImageHeight }) => {
  const primaryImage = icon?.primaryImage
  const primaryImageFile = primaryImage?.file

  let imageAnimation
  if (primaryImageFile?.contentType?.includes("json")) {
    imageAnimation = primaryImageFile?.url
  }

  return (
    <FeatureItem>
      <FeatureItemWrapper>
        <FeatureItemIcon unrestrictImageHeight={unrestrictImageHeight}>
          {imageAnimation ? (
            <LottieWrapper imageAnimation={imageAnimation} />
          ) : (
            <OptimizedImage
              image={primaryImage?.gatsbyImageData}
              src={primaryImageFile?.url}
              alt={primaryImage?.description || primaryImage?.title || "image-animation"}
            />
          )}
        </FeatureItemIcon>
        <FeatureDescriptionWrapper>
          {icon?.featureGridTitle && (
            <FeatureItemTitle>{icon?.featureGridTitle}</FeatureItemTitle>
          )}
          {icon?.featureGridDescription && (
            <FeatureItemDescription>
              {icon?.featureGridDescription}
            </FeatureItemDescription>
          )}
        </FeatureDescriptionWrapper>
        {icon?.url && <Link to={icon?.url}>Learn More</Link>}
      </FeatureItemWrapper>
    </FeatureItem>
  )
}
