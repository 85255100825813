import React from "react"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"
import { formatLabel, formatSchemaUrl, formatUrl } from "../Hero/utils"

const Seo = ({
  title,
  description,
  image,
  imageAlt,
  article,
  noIndex,
  canonical,
  metaTags,
  isBlog,
  postData,
}) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
    defaultImageAlt,
  } = site.siteMetadata

  const seoObj = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: image || `${siteUrl}${defaultImage}`,
    imageAlt: imageAlt || defaultImageAlt,
    url: canonical || `${siteUrl}${pathname || ""}`,
    metaTags: metaTags || "Workgrid, digital workplace, enterprise software",
  }

  let currentBread = [];
  const paths = pathname?.split('/').filter(item => item !== '') || [];
  console.log(paths, pathname, "pathnamepathnamepathname===")
    
  paths?.map((path, index) => {
    const isLast = index === paths.length - 1
    const label = isLast ? (title || defaultTitle) : formatLabel(path)

    currentBread.push({
      "@type": "ListItem",
      "position": index + 2,
      "item": {
        "@id": `https://workgrid.com/${formatSchemaUrl(paths, index)}`,
        "name": label,
      },
    });
  });
  
  const schema = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "item": {
          "@id": "https://workgrid.com/",
          "name": "Home",
        },
      },
      ...currentBread
    ],
  };

  const blogSchema = isBlog ? {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "headline" : `${title}`,
    "description": `${description}`,
    "author" : {
      name: `${postData?.author && postData?.author[0] ? postData?.author[0]?.fullName : 'Workgrid'}`
    },
    "datePublished" : `${postData?.publishDate}`,
    "image" : `${image || postData?.featuredImage?.file?.url}`,
  } : null;

  return (
    <Helmet
      htmlAttributes={{ lang: "en-US" }}
      title={seoObj.title}
      titleTemplate={titleTemplate}
    >
      <meta name="description" content={seoObj.description} />
      <meta name="image" content={seoObj.image} />

      {seoObj.url && <meta property="og:url" content={seoObj.url} />}

      {article && <meta property="og:type" content="article" />}

      {seoObj.title && <meta property="og:title" content={seoObj.title} />}

      {seoObj.description && (
        <meta property="og:description" content={seoObj.description} />
      )}

      {seoObj.image && (
        <meta name="image" property="og:image" content={seoObj.image} />
      )}

      {metaTags && <meta name="keywords" content={metaTags} />}

      <meta name="twitter:card" content="summary_large_image" />

      {seoObj.title && <meta name="twitter:title" content={seoObj.title} />}

      {seoObj.description && (
        <meta name="twitter:description" content={seoObj.description} />
      )}

      {seoObj.image && <meta name="twitter:image" content={seoObj.image} />}

      {!noIndex && <meta name="robots" content="index,follow" />}
      {!noIndex && <meta name="googlebot" content="index,follow" />}

      {noIndex && <meta name="robots" content="noindex" />}

      <script type="application/ld+json">
        {JSON.stringify(schema)}
      </script>
      
      {blogSchema && (
        <script id="blog-post-markup" type="application/ld+json">
          {JSON.stringify(blogSchema)}
        </script>
      )}
    </Helmet>
  )
}

export default Seo

const query = graphql`
  query SeoQuery {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        defaultImageAlt: imageAlt
        twitterUsername
      }
    }
  }
`
