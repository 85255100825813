export const getYoutubeId = url => {
  const youtubeRegExp =
      /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = url?.match(youtubeRegExp)
  return match && match[1].length === 11 ? match[1] : false
}

export const getVimeoId = url => {
  const vimeoRegExp = /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_]+)?/i
  const match = url?.match(vimeoRegExp)
  return match && match[1] ? match[1] : false
}

export const getCustomUrl = videoFile => {
  const videoUrl = 'https:' + videoFile?.file?.url
  return videoUrl
}

export const validatePlay = (video, setChannel, setVideo, setPlay) => {
  if (video?.videoUrl) {
    if (video?.videoUrl?.includes("youtu.be")) {
      const youtubeId = getYoutubeId(video?.videoUrl)
      setChannel("youtube")
      setVideo(youtubeId)
      setPlay(true)
    }
    if (video?.videoUrl.includes("vimeo")) {
      const vimeoId = getVimeoId(video?.videoUrl)
      setChannel("vimeo")
      setVideo(vimeoId)
      setPlay(true)
    }
  } else if (video?.videoFile) {
    const customId = getCustomUrl(video?.videoFile)
    setChannel("custom")
    setVideo(customId)
    setPlay(true)
  }
}

export const fetchYoutubeData = async (videoId) => {
  try {
    const response = await fetch(
      `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&part=contentDetails,snippet&key=${process.env.YOUTUBE_API_KEY}`
    );
    const data = await response.json();
    const video = data.items[0];
    return {
      duration: video.contentDetails.duration,
      uploadDate: video.snippet.publishedAt,
    };
  } catch {
    return {
      duration: '',
      uploadDate: '',
    };
  }
};

export const fetchVimeoData = async (videoId) => {
  try {
    const response = await fetch(`https://vimeo.com/api/v2/video/${videoId}.json`);
    const data = await response.json();
    const video = data[0];
    return {
      duration: video.duration,
      uploadDate: video.upload_date,
    };
  } catch {
    return {
      duration: '',
      uploadDate: '',
    };
  }
};

export const getCustomVideoDuration = async (videoUrl) => {
  return new Promise((resolve) => {
    const video = document.createElement("video");
    video.src = videoUrl;
    video.addEventListener("loadedmetadata", () => {
      resolve(video.duration);
    });
  });
};

export const fetchCustomVideoMetadata = async (videoFileUrl) => {
  try {
    const response = await fetch(videoFileUrl, { method: 'HEAD' });
    const lastModified = response.headers.get('Last-Modified');
    return new Date(lastModified).toISOString();
  } catch {
    return '';
  }
};

export const formatDuration = (seconds) => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);
  return `PT${h > 0 ? h + "H" : ""}${m > 0 ? m + "M" : ""}${s > 0 ? s + "S" : ""}`;
};
