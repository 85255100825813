import React, { useState, useEffect } from "react"
import { isBrowser } from "@utils/isBrowser"
import { Layout } from "@components/Layout"
import Seo from "../components/Seo/Seo"
import { HeroBlogDetail } from "@components/Hero/HeroBlogDetail"
import { BlogDetailCardDeck } from "@components/Blog/BlogDetailCardDeck"
import { BlogDetailRelatedShareDesktop } from "../components/Blog/BlogDetailRelatedShareDesktop"
import FAQAccordion from "@components/Accordions/FAQAccordion"
import ConversionPanel from "@components/ConversionPanel/ConversionPanel"
import { BlogSocialShareRow } from "@components/Blog/BlogDetail/BlogSocialShareRow"
import { TableOfContents } from "@components/Blog/BlogDetail/TableOfContents"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { graphql } from "gatsby"
import { theme } from "@theme"
import { Button } from "@atoms"
import { postDetailResolveRichText } from "../utils/resolve-rich-text/blogDetailResolveRichText"
import MktoForm from "../components/Form/MktoForm"
import {
  PostContentWrapper,
  HorizontalScroll,
  ScrollBtnWrapper,
  PostContentGrid,
  BodyContentWrapper,
  FootnoteCaption,
} from "./styles/blogDetail.styled"

const headingStyles = {
  padding: "22px 0px 8px 0px",
}

function BlogPostTemplate(props) {
  const [showScrollToTop, setShowScrollToTop] = useState(false)
  const [scrollProgress, setScrollProgress] = useState(100)
  const postData = props?.data?.allContentfulBlogPost?.edges[0]?.node
  const allPosts = props?.data?.allPosts?.edges
  const cardDeckPosts = allPosts.slice(0, 3)
  const title = postData?.title
  const form = props?.data?.contentfulComponentForm
  const seo = postData?.pageSeo

  // these are fixed components in the template, such as the "More from the Blog" section and Conversion Panel underneath the blog body:
  const templateComponents = props.data.contentfulPage.components

  useEffect(() => {
    if (!isBrowser()) {
      return null
    }
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 1000) {
        setShowScrollToTop(true)
      } else {
        setShowScrollToTop(false)
      }

      var navHeight = document.getElementById("nav_wrapper")
        ? document.getElementById("nav_wrapper").clientHeight
        : 100
      var heroHeight = document.getElementById("blog_detail_hero_wrapper")
        ? document.getElementById("blog_detail_hero_wrapper").clientHeight
        : 500
      var windowScrollTop = window.pageYOffset - heroHeight + navHeight
      var docHeight = document.getElementById("blog_detail_content_wrapper")
        ? document.getElementById("blog_detail_content_wrapper").clientHeight
        : document.body.clientHeight - heroHeight
      var progress = 100 - (windowScrollTop / docHeight) * 100
      setScrollProgress(progress > 100 ? 100 : progress < 0 ? 0 : progress)
    })
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    })
  }

  const allBlocksAndEntries = JSON.parse(postData?.body?.raw).content
  const tableOfContentsHeadings = allBlocksAndEntries.filter(
    block => block?.nodeType === "heading-2" || block?.nodeType === "heading-4"
  )

  return (
    <Layout>
      <Seo
        title={seo?.seoTitle || title}
        description={seo?.seoDescription}
        noIndex={seo?.noIndex}
        canonical={seo?.canonical}
        image={seo?.graphImage?.file?.url || postData?.featuredImage?.file?.url}
        imageAlt={seo?.graphImage?.description}
        metaTags={seo?.metaTags}
        article={seo?.article}
        postData={postData}
        isBlog
      />
      <HorizontalScroll
        className="horizontal-scroll-mobile"
        style={{ width: `${scrollProgress}%` }}
      />
      <HeroBlogDetail
        postData={props?.data?.allContentfulBlogPost?.edges[0]?.node}
      />
      <HorizontalScroll
        className="horizontal-scroll"
        style={{ width: `${scrollProgress}%` }}
      />
      <PostContentWrapper id="blog_detail_content_wrapper">
        <PostContentGrid>
          <div>
            {postData.tableOfContents && (
              <TableOfContents
                style={{ marginTop: "20px", marginBottom: "80px" }}
                tableOfContentsHeadings={tableOfContentsHeadings}
              />
            )}
            <BodyContentWrapper theme={theme}>
              {postDetailResolveRichText(postData, headingStyles)}
            </BodyContentWrapper>
            <div style={{ padding: "16px 0px" }} />
            {postData?.footnoteCaption?.raw && (
              <FootnoteCaption>
                <div>
                  {documentToReactComponents(
                    JSON.parse(postData?.footnoteCaption?.raw)
                  )}
                </div>
              </FootnoteCaption>
            )}
            <MktoForm component={form} />
            {postData.faqSection && (
              <FAQAccordion blog component={postData?.faqSection} />
            )}
          </div>
          <div>
            <BlogDetailRelatedShareDesktop
              allPosts={allPosts}
              relatedCategories={postData?.category}
            />
            <BlogSocialShareRow
              slug={postData?.slug}
              iconColor={"blue"}
              backgroundColor={"#F2F4F7"}
              seo={seo}
            />
          </div>
        </PostContentGrid>
        <ScrollBtnWrapper
          showScrollToTop={showScrollToTop}
          onClick={scrollToTop}
        >
          <Button ctaVariant="secondary">Back To Top</Button>
        </ScrollBtnWrapper>
      </PostContentWrapper>
      {templateComponents[0] && (
        <BlogDetailCardDeck
          blogPosts={cardDeckPosts}
          heading={templateComponents[0]?.heading}
          subhead={templateComponents[0]?.subheading}
          headingSize={templateComponents[0]?.headingSize}
          kicker={templateComponents[0]?.kicker}
          // categories={categories}
        />
      )}
      <ConversionPanel component={templateComponents[1]} />
    </Layout>
  )
}

export default BlogPostTemplate

export const BlogPostQuery = graphql`
  query BlogDetailQuery($id: String) {
    contentfulPage(internalName: { eq: "Blog Detail Template" }) {
      id
      components {
        ... on ContentfulComponentConversionPanel {
          __typename
          id
          type
          textAlign
          alignContent
          kicker
          background
          contentful_id
          heading
          headingSize
          internalName
          noTopPadding
          noBottomPadding
          callsToAction {
            contentful_id
            id
            internalLink
            label
            type
            link
            variant
          }
        }
        ... on ContentfulComponentCardDeck {
          __typename
          id
          internalName
          type
          cardType
          backgroundColor
          kicker
          slide2Kicker
          headingSize
          heading
          slide2Heading
          noTopPadding
          noBottomPadding
          subheading {
            subheading
          }
          slide2Subheading {
            slide2Subheading
          }
        }
      }
      contentful_id
      internalName
    }
    contentfulComponentForm(
      id: { eq: "07525be7-87ce-58c3-99d3-43e3d90cde1c" }
    ) {
      __typename
      id
      type
      anchorLinkId
      internalName
      marketoFormId
      background
    }
    allContentfulBlogPost(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          title
          externalTitle
          publishDate
          slug
          pageSeo {
            graphImage {
              description
              title
              file {
                url
              }
            }
            seoTitle
            seoDescription
            canonical
            noIndex
            metaTags
            article
          }
          description {
            description
          }
          category {
            backgroundColor
            fontColor
            title
            id
          }
          author {
            fullName
            headshot {
              gatsbyImageData
              file {
                url
              }
            }
          }
          body {
            raw
            references {
              ... on ContentfulComponentImage {
                id
                internalLink
                url
                caption {
                  raw
                }
                alignCaption
                contentful_id
                description
                internal {
                  type
                }
                primaryImage {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                    width: 1200
                  )
                  file {
                    url
                    contentType
                    details {
                      image {
                        height
                        width
                      }
                    }
                  }
                  id
                  title
                }
              }
              ... on ContentfulComponentVideo {
                id
                caption {
                  raw
                }
                alignCaption
                contentful_id
                autoplay
                title
                videoUrl
                videoFile {
                  title
                  description
                  gatsbyImageData
                  file {
                    url
                  }
                }
                internal {
                  type
                }
              }
              ... on ContentfulComponentAlternateQuoteBlock {
                contentful_id
                __typename
                id
                type
                internal {
                  type
                }
                quote {
                  quote
                }
                author {
                  role
                  headshot {
                    gatsbyImageData
                    file {
                      url
                    }
                    description
                  }
                  fullName
                  company {
                    name
                    logo {
                      gatsbyImageData
                      file {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
          tableOfContents
          editorPick
          footnoteCaption {
            raw
          }
          faqSection {
            heading
            id
            subhead
            faQs {
              answer {
                raw
              }
              question
              id
            }
          }
          featuredImage {
            gatsbyImageData
            file {
              url
            }
            title
            description
          }
        }
      }
    }
    allPosts: allContentfulBlogPost(
      sort: { fields: publishDate, order: DESC }
      filter: { id: { nin: [$id, "8c7ce552-fe16-5084-8505-c662bca6b48a"] } }
    ) {
      edges {
        node {
          id
          title
          publishDate
          slug
          featuredImage {
            gatsbyImageData
            file {
              url
            }
            title
            description
          }
          description {
            description
          }
          category {
            backgroundColor
            title
            id
          }
          author {
            fullName
            headshot {
              gatsbyImageData
              file {
                url
              }
            }
          }
          body {
            raw
          }
          editorPick
          faqSection {
            heading
            id
            subhead
            faQs {
              answer {
                raw
              }
              question
              id
            }
          }
        }
      }
    }
  }
`
