import React, { useEffect } from "react"

import { useState } from "react"
import ModalVideo from "react-modal-video"
import { VideoFrame } from "../../styles/components/VideoFrame"
import OptimizedImage from "../../utils/optimizedImage"
import {
  VideoSectionWrapper,
  ImageWrapper,
  ThumbnailWrapper,
} from "./styles/VideoSectionWebinar.styled"
import { fetchCustomVideoMetadata, fetchVimeoData, fetchYoutubeData, formatDuration, getCustomVideoDuration, validatePlay } from "../../utils/validateVideo"
import onKeyDown from "../../utils/onKeyDown"
import { Helmet } from "react-helmet"

export const VideoSectionWebinar = ({ component }) => {
  const [play, setPlay] = useState(false)
  const [videoId, setVideo] = useState()
  const [channel, setChannel] = useState("")
  const [duration, setDuration] = useState("");
  const [uploadDate, setUploadDate] = useState("");

  const playVideo = featuredVideo => {
    validatePlay(featuredVideo, setChannel, setVideo, setPlay)
  }

  useEffect(() => {
    const fetchVideoMetadata = async () => {
      if (channel === "youtube") {
        const videoData = await fetchYoutubeData(videoId);
        setDuration(formatDuration(videoData.duration));
        setUploadDate(videoData.uploadDate);
      } else if (channel === "vimeo") {
        const videoData = await fetchVimeoData(videoId);
        setDuration(formatDuration(videoData.duration));
        setUploadDate(videoData.uploadDate);
      } else if (channel === "custom") {
        const videoDuration = await getCustomVideoDuration(videoId);
        const uploadDate = await fetchCustomVideoMetadata(videoId);
        setDuration(formatDuration(videoDuration));
        setUploadDate(uploadDate || "2025-01-01T12:00:00Z");
      }
    };
  
    if (videoId) fetchVideoMetadata();
  }, [videoId, channel])
  
  const schema = {
    "@context": "https://schema.org",
    "@type": "VideoObject",
    "embedUrl": component?.videoFile?.file?.url ? 'https:' + component?.videoFile?.file?.url : component?.videoUrl,
    "thumbnailUrl": component?.thumbnail?.file?.url || component?.thumbnail?.gatsbyImageData?.images?.sources[0]?.srcSet || 'Video Thumbnail',
    "name": component?.title || 'Video Title',
    "description": component?.description || 'Video Description',
    "duration": duration,
    "uploadDate": uploadDate || "2025-01-01T12:00:00Z",
  };

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schema)}
        </script>
      </Helmet>
      <VideoSectionWrapper>
        <div className="content">
          <div
            style={{
              margin: "0 auto",
              maxWidth: "1200px",
            }}
          >
            <ImageWrapper>
              <ThumbnailWrapper>
                <figure className="blog-image-wrapper">
                  {component?.thumbnail ? (
                    <div>
                      <OptimizedImage
                        image={component?.thumbnail?.gatsbyImageData}
                        src={component?.thumbnail?.file?.url}
                        alt={component?.thumbnail?.description || component?.thumbnail?.title || "thumbnail"}
                      />
                    </div>
                  ) : (
                    <div className="emptyImg" />
                  )}
                </figure>
                <div className="playicon">
                  <span
                    onClick={() => playVideo(component)}
                    role="button"
                    aria-label="play"
                    tabIndex={0}
                    onKeyDown={e =>
                      onKeyDown(e, () => playVideo(component), "Enter")
                    }
                  >
                    <img src="/icons/video-play.svg" alt="watch video button" />
                  </span>
                </div>
              </ThumbnailWrapper>
            </ImageWrapper>
          </div>
        </div>
        {typeof window !== "undefined" && (
          <VideoFrame>
            <ModalVideo
              channel={channel}
              isOpen={play}
              videoId={videoId}
              url={channel === "custom" && videoId}
              onClose={() => setPlay(false)}
              autoplay={true}
            />
          </VideoFrame>
        )}
      </VideoSectionWrapper>
    </>
  )
}
