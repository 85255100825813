import React, { useState } from 'react';
import { FAQAccordionWrapper, FAQAccordionItem, FAQAccordionHeading, FAQAccordionSubheading, FAQAccordionContent, ItemWrapper } from './styles/FAQAccordion.styled';
import { switchbackResolveRichText } from "../../utils/resolve-rich-text/switchbackResolveRichText"
import onKeyDown from "../../utils/onKeyDown"
import getFaqMeta from './getFaqMeta';
import { Helmet } from 'react-helmet';

const FAQAccordion = ({ component, blog }) => {
  const [faqOpened, setFaqOpened] = useState({});

  const handleFaqClicked = (index) => {
    setFaqOpened({
      ...faqOpened,
      [index]: !faqOpened[index],
    });
  }
  const faqSchema = component.faQs && getFaqMeta(component.faQs);

  return (
    <>
      {faqSchema && (
        <Helmet>
          {Object.keys(faqSchema).length && (
            <script id="frequently-asked-questions-markup" type="application/ld+json">
              {JSON.stringify(faqSchema)}
            </script>
          )}
        </Helmet>
      )}
      <FAQAccordionWrapper
        data-comp={FAQAccordion.displayName}
        blog={blog}
        noTopPadding={component?.noTopPadding}
        noBottomPadding={component?.noBottomPadding}
      >
        {component?.heading && <FAQAccordionHeading>{component?.heading}</FAQAccordionHeading>}
        {component?.subhead && <FAQAccordionSubheading>{component?.subhead}</FAQAccordionSubheading>}
        {component?.faQs?.length > 0 && (
          <FAQAccordionContent>
            {component.faQs.map((item, index) => (
              <FAQAccordionItem className={`${faqOpened[index] ? 'opened' : ''}`} key={index}>
                <ItemWrapper className={`${faqOpened[index] ? 'opened' : ''}`}>
                  <div
                    className="item-heading"
                    role="button"
                    onClick={() => handleFaqClicked(index)}
                    onKeyDown={(e) => onKeyDown(e, () => handleFaqClicked(index))}
                    tabIndex={0}
                    aria-label={faqOpened[index] ? `Collapse ${item?.question}` : `Expand ${item?.question}`}
                    aria-expanded={faqOpened[index]}
                  >
                    {item?.question}
                  </div>
                  <div className="item-content">
                    {switchbackResolveRichText(item?.answer)}
                  </div>
                </ItemWrapper>
              </FAQAccordionItem>
            ))}
          </FAQAccordionContent>
        )}
      </FAQAccordionWrapper>
    </>
  );
};

FAQAccordion.displayName = 'FAQAccordion';
export default FAQAccordion
