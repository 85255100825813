const getFaqMeta = (accordionItems, schemaName) => {
  const extractPlainText = (unresolvedContentObject) => {
  let plainText = '';
  const bodyContent = JSON.parse(unresolvedContentObject?.raw);
  
    const traverseNodes = (nodes) => {
      nodes.forEach((node) => {
        if (node.nodeType === 'text') {
          plainText += node.value + ' ';
        } else if (node.content) {
          traverseNodes(node.content);
        }
      });
    };
  
    if (bodyContent && bodyContent.content) {
      traverseNodes(bodyContent.content);
    }
  
    return plainText.trim(); // Remove extra whitespace
  };

  const Accordion = accordionItems.map(faq => ({
    '@type': 'Question',
    name: faq.question || 'Question',
    text: faq.question,
    acceptedAnswer: {
      '@type': 'Answer',
      text: extractPlainText(faq.answer)
    },
  }));

  return {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    name: schemaName || 'Frequently Asked Questions',
    mainEntity: Accordion,
  };
};

export default getFaqMeta;
